<template>
  <div class="wrapper-container">
    <div class="describe-container-box">
      <div class="title">供应链赋能·方案详情</div>
      <div class="sub-title">供应链赋能增长策略</div>
      <div class="large-paragraph-describe">
        全球蛙为品牌商提供全域全渠道零售·分销·批销数智化SaaS系统，加入蛙选联盟，通过创新合作模式，利用数字化特有的“链接”优势，联合供销两端共筑公私域，打破商品壁垒，提升流量，打造新生态，助力品牌商实现流通效率提升、业绩增长！
      </div>
    </div>
    <img
      class="large-image-h448"
      src="@/image/brand/img_edm_isc_brand@3x.png"
    />
    <img
      class="large-image-h448"
      src="@/image/brand/img_procurement_isc_brand@3x.png"
    />
    <img
      class="large-image-h448"
      src="@/image/brand/img_presale_isc_brand@3x.png"
    />
    <img
      class="large-image-h448"
      src="@/image/brand/img_tailings_isc_brand@3x.png"
    />
    <div class="func-module-container">
      <div class="func-title">供应链赋能·应用案例</div>
      <div class="func-sub-title">联动供需两端合力营销，助力品牌销售增长</div>
      <div class="demo">
        <div class="demo-title-tag">C2M业务经典案例</div>
        <div class="demo-commodity-container">
          <img
            class="small-image-h240"
            src="@/image/brand/img_lk_isc_brand@3x.png"
          />
          <img
            class="small-image-h240"
            src="@/image/brand/img_amn_isc_brand@3x.png"
          />
        </div>
        <div class="demo-commodity-container">
          <img
            class="small-image-h240"
            src="@/image/brand/img_trt_isc_brand@3x.png"
          />
          <img
            class="small-image-h240"
            src="@/image/brand/img_3c_isc_brand@3x.png"
          />
        </div>
      </div>
      <div class="demo">
        <div class="demo-title-tag">批销集采经典案例</div>
        <div class="demo-commodity-container">
          <img
            class="small-image-h472"
            src="@/image/brand/img_traffic_isc_brand@3x.png"
          />
          <img
            class="small-image-h472"
            src="@/image/brand/img_volume_isc_brand@3x.png"
          />
        </div>
      </div>
    </div>
    <action-sheet module="新品牌供应链赋能">
      <template #extra-title>
        <div class="extra-title">相关文章</div>
      </template>
    </action-sheet>
  </div>
</template>

<script>
export default {
  data: () => ({
    subscriptImages: [
      require("@/image/retail/img_edm_ isc@3x.png"),
      require("@/image/retail/img_category_ isc@3x.png"),
      require("@/image/retail/img_brand_ isc@3x.png"),
      require("@/image/retail/img_market_ isc@3x.png"),
    ],
  }),
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/solution.common.scss";
.large-paragraph-describe {
  margin-bottom: 0 !important;
}
.demo {
  .demo-title-tag {
    width: 340px;
    height: 56px;
    line-height: 56px;
    color: $textColorfff;
    font-size: 26px;
    font-family: PingFang SC, PingFang SC-Semibold;
    background: linear-gradient(90deg, #0578d4, #0fe198);
    border-radius: 8px;
    padding-left: 30px;
    box-sizing: border-box;
    margin: 40px 0;
  }
  .demo-commodity-container {
    display: flex;
    overflow-x: scroll;
    flex-wrap: nowrap;
    padding: 0 !important;
    margin-bottom: 30px;
    &::-webkit-scrollbar {
      display: none;
      width: 0px;
      height: 0px;
    }
    .small-image-h240,
    .small-image-h472 {
      margin-right: 30px;
    }
    .small-image-h240 {
      height: 240px;
    }
  }
}
</style>
